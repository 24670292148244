import styled, {css} from 'styled-components'
import Text from '../visual/typography/Text'
import ContentContainer from '../visual/components/ContentContainer'
import AppShell from '../visual/components/AppShell'
import media from '../utils/media'


const StyledContentContainer = styled(ContentContainer)`
  display: flex;
  margin: 2.5rem auto 0;

  ${media.down('mobile')(css`
    padding: 2.375rem 2.125rem 1.375rem 1.5rem;
    margin-top: 26rem;
  `)}
`

const StyledText = styled(Text)`
  ${media.down('mobile')(css`
    text-align: center;
  `)}
`

const NotFoundPage = () => {
  return (
    <AppShell title="Nenašla sa žiadna stránka">
      <StyledContentContainer>
        <StyledText component="h1" textStyle="h1" color="textInverted">Nenašla sa žiadna stránka</StyledText>
        <StyledText component="p" textStyle="p" color="textInverted">
          Ospravedlňujeme sa, ale táto stránka neexistuje.
        </StyledText>
      </StyledContentContainer>
    </AppShell>
  )
}

export default NotFoundPage
